import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Button, Toast, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';

const AllProject = ({ user }) => {
    const [allProjects, setAllProjects] = useState([]);
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");

    const filteredProjects = allProjects.filter((project) => {
        const id = project._id || ""; 
        const name = project.name || ""; 
        const email = project.userEmail || ""; 
      
        return (
          id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });


    useEffect(() => {
        fetchAllProjects();
    }, []);

    const fetchAllProjects = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/projects');
            if (response.ok) {
                const projectsData = await response.json();
                const projectsWithUserInfo = await Promise.all(projectsData.map(async project => {
                    const userResponse = await fetch(`https://api.streampixel.io/pixelStripeApi/users/${project.user}`);
                    if (userResponse.ok) {
                        const userData = await userResponse.json();
                        return {
                            ...project,
                            userEmail: userData?.email,
                            shareId: project.shareId || '',
                            appName: project.appName || '',
                            subId: project.subId,
                            orderId: project.orderId,
                            status: project.status,
                            disableByAdmin: project.disableByAdmin,
                            subscriptionStatus: project.subscriptionStatus || '',
                            totalAmount: project.totalAmount || 0,
                            subscriptionStartDate: project.subscriptionStartDate || '',
                            subscriptionEndDate: project.subscriptionEndDate || '',
                            delay: project.delay
                        };
                    } else {
                        return project;
                    }
                }));

                const sortedData = projectsWithUserInfo.sort((a, b) => moment(a.subscriptionEndDate) - moment(b.subscriptionEndDate));
                setAllProjects(sortedData);
            } else {
                console.error('Failed to fetch projects data');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const saveProject = async (project) => {
        project.userId = user._id;
        project.webIp = process.env.REACT_APP_WEBIP;
        
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${project._id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(project)
            });

            if (response.ok) fetchAllProjects();
            else console.error('Failed to save project:', project);
        } catch (error) {
            console.error('Error saving project:', project, error);
        }
    };

    const deletProject = async (project) => {
        try {
            const response = await axios.delete(`https://api.streampixel.io/pixelStripeApi/projects/${project._id}`, {
                data: { userId: user._id, webIp: process.env.REACT_APP_WEBIP }
            });

            if (response.status === 204) {
                fetchAllProjects();
                setShowMessage(true);
                setAlertVarient('success');
                setAlertMessage("Project Deleted Successfully");
            }
        } catch (error) {
            console.error('Error deleting project:', project, error);
        }
    };

    const columns = [
        { dataField: '_id', text: 'ProjectId' },
        { dataField: 'subId', text: 'Subscription Id' },
        { dataField: 'orderId', text: 'Order Id' },
        { dataField: 'name', text: 'Name', editable: false },
        { dataField: 'userEmail', text: 'User Email', editable: false },
        { dataField: 'allowedUsers', text: 'No. Of Users' },
        { dataField: 'delay', text: 'Delay' },
        {
            dataField: 'subscriptionStartDate',
            text: 'Start Date',
            formatter: (cell) => cell ? moment(cell).format('DD/MM/YYYY') : '',
            editor: { type: Type.DATE }
        },
        {
            dataField: 'subscriptionEndDate',
            text: 'End Date',
            formatter: (cell) => cell ? moment(cell).format('DD/MM/YYYY') : '',
            editor: { type: Type.DATE }
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell) => (cell ? 'Active' : 'Inactive'),
            editor: {
                type: Type.SELECT,
                options: [
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            }
        },
        {
            dataField: 'disableByAdmin',
            text: 'Disable By Admin',
            formatter: (cell) => (cell ? 'Active' : 'Inactive'),
            editor: {
                type: Type.SELECT,
                options: [
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' }
                ]
            }
        },
        { dataField: 'shareId', text: 'Share Id' },
        { dataField: 'appName', text: 'App path' },
        {
            dataField: 'reservedIp',
            text: 'Reserved IP',
            editor: { type: Type.TEXTAREA },
            formatter: (cell) => (Array.isArray(cell) ? cell.join(', ') : cell),
            style: { width: "250px", display: 'flex' }
        },
        { dataField: 'subscriptionStatus', text: 'Subscription Status' },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => (
                <Button variant="danger" onClick={() => deletProject(row)}>Delete</Button>
            ),
            editable: false
        }
    ];

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue, newValue, row, column) => {
            if (column.dataField === 'reservedIp') {
                row.reservedIp = newValue.split(',').map(ip => ip.trim());
            }
            if (column.text !== 'Raise Invoice') {
                if (column.text === 'Status') {
                    row.status = newValue === "true";
                }
                saveProject(row);
            }
        }
    });

    const paginationOptions = {
        sizePerPage: 10,
        hideSizePerPage: true,
        showTotal: true
    };

    return (
        <Container>
            <Toast bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
                <Toast.Header />
                <Toast.Body>{alertMessage}</Toast.Body>
            </Toast>
            <Row>
                <Col xs={2}><h4>All Projects</h4></Col>

                <Col xs = {4}>
      <input
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          marginBottom: "10px",
          padding: "8px",
          width: "300px",
          fontSize: "16px",
        }}
      />
                </Col>
                <Col md={{ span: 2, offset: 4 }}>
                    <div style={{ textAlign: 'end' }}>
                        <Button onClick={fetchAllProjects}>Refresh Data</Button>
                    </div>
                </Col>
            </Row>
            <BootstrapTable
                keyField="_id"
                data={filteredProjects}
                columns={columns}
                cellEdit={cellEdit}
                pagination={paginationFactory(paginationOptions)}
            />
        </Container>
    );
};

export default AllProject;
