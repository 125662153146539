import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {

    const isLandingPage = window.location.pathname === '/'; // Adjust the path as per your landing page URL
    return (
        <div >
        <footer className="footer mt-auto py-3" style={{ position: "fixed", bottom: 0, height: "70px", width: "100%", backgroundColor: "#161616", zIndex: "100" }}>
                <Container>
                    <Row className='mt-3'>
                        <Col className='foostyle'>
                            <p style={{ fontSize:"12px",fontFamily: "Comfortaa-Light", textAlign: isLandingPage ? "center" : "left" }}>
                                &copy; 2024 Vardhman Infinity (P) Ltd.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
};

export default Footer;
