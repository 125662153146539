import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Container, Button,Toast, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';

const StartCmd = ({ user }) => {
    const [allCmds, setAllCmds] = useState([]);
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);
    useEffect(() => {
        fetchAllCmds();
    }, []);

    const fetchAllCmds = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/streamStartCmd');
            if (response.ok) {
                const usersData = await response.json();
                setAllCmds(usersData);
            } else {
                console.error('Failed to fetch users data');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };





    const saveCmd = async (cmd) => {

        console.log(cmd);

        cmd.userId = user._id;
        cmd.webIp = process.env.REACT_APP_WEBIP;
        
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/streamStartCmd/${cmd._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cmd) // Send the whole project object
            });

            if(response.ok){
                fetchAllCmds();
            }
            if (!response.ok) {
                console.error('Failed to save cmd:', cmd);
            }
        } catch (error) {
            console.error('Error saving cmd:', cmd, error);
        }
    };




    const columns = [
      
        { dataField: 'cmd', text: 'CMD' },
        {
            dataField: 'reservedSystem',
            text: 'Reserved System',
            editor: { type: Type.TEXTAREA },
            formatter: (cell) => (Array.isArray(cell) ? cell.join(', ') : cell),
            style:{width:"250px",display:'flex'}
        },


    ];


    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue, newValue, row, column) => {
            console.log(`Cell ${column.text} updated for cmd ${row.name}: ${oldValue} -> ${newValue}`);
    
            // Convert the 'reservedIp' field to an array
            if (column.dataField === 'reservedSystem') {
                row.reservedSystem = newValue.split(',').map(ip => ip.trim());
            }
    
            saveCmd(row);
        }
    });

    return (
        <Container>

<Toast bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
<Row>
    <Col xs={2}><h4>All Cmd</h4></Col>
            
            
    <Col md={{ span: 2, offset: 8, }}>
    <div style={{textAlign:'end'}}>
    <Button  onClick={fetchAllCmds}>Refresh Data</Button>
</div>
</Col>
            
    </Row>
            <BootstrapTable
            
                keyField="_id"
                data={allCmds.map(cmd => ({
                    ...cmd,
                     
                }))}                columns={columns}
                cellEdit={cellEdit}
            />
        </Container>
    );
};

export default StartCmd;
