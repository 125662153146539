import React, { useEffect, useState } from 'react';
import { Container, Button, Toast, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const AllUsers = ({ user }) => {
    const [users, setUsers] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const filteredUsers = users.filter((user) => {
        const contact = user.contact || ""; 
        const name = user.name || ""; 
        const email = user.email || ""; 
      
        return (
            contact.toLowerCase().includes(searchTerm.toLowerCase()) ||
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            email.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const fetchAllUsers = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/users');
            if (response.ok) {
                const usersData = await response.json();
                setUsers(usersData);
            } else {
                console.error('Failed to fetch users data');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const deleteUser = async (userId) => {
        try {
            const response = await axios.delete(`https://api.streampixel.io/pixelStripeApi/users/${userId}`, {
                data: {
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP
                }
            });
            if (response.status === 204) {
                fetchAllUsers(); // Refresh the user list after deletion
                setAlertMessage('User deleted successfully.');
                setAlertVariant('success');
            } else {
                console.error('Failed to delete user');
                setAlertMessage('Failed to delete user.');
                setAlertVariant('danger');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            setAlertMessage('Error deleting user.');
            setAlertVariant('danger');
        }
        setShowMessage(true);
    };

    const handleTrialDoneChange = async (userId, newValue) => {
        try {
            const response = await axios.put(`https://api.streampixel.io/pixelStripeApi/users/${userId}`, {
                isTrialDone: newValue,
                userId: user._id,
                webIp: process.env.REACT_APP_WEBIP
            });
            if (response.status === 200) {
                fetchAllUsers(); // Refresh the user list after update
                setAlertMessage('User trial status updated successfully.');
                setAlertVariant('success');
            } else {
                console.error('Failed to update user trial status');
                setAlertMessage('Failed to update user trial status.');
                setAlertVariant('danger');
            }
        } catch (error) {
            console.error('Error updating user trial status:', error);
            setAlertMessage('Error updating user trial status.');
            setAlertVariant('danger');
        }
        setShowMessage(true);
    };

    // Columns definition for Bootstrap Table
    const columns = [
        { 
            dataField: 'name', 
            text: 'Name' 
        },
        { 
            dataField: 'email', 
            text: 'Email' 
        },
        { 
            dataField: 'contact', 
            text: 'Contact' 
        },
        { 
            dataField: 'foundUs', 
            text: 'Found Us' 
        },
        { 
            dataField: 'address',
            text: 'Address',
            formatter: (cellContent, row) => {
                return `${row.address?.line1 || ''}, ${row.address?.city || ''}, ${row.address?.state || ''}, ${row.address?.country || ''}, ${row.address?.postalcode || ''}`;
            }
        },
        { 
            dataField: 'trialDone', 
            text: 'Free Trial',
            formatter: (cellContent, row) => (
                <Form.Check
                    type="switch"
                    id={`custom-switch-${row._id}`}
                    label=""
                    checked={row.trialDone}
                    onChange={(e) => handleTrialDoneChange(row._id, e.target.checked)}
                />
            )
        },
        { 
            dataField: 'actions',
            text: 'Actions',
            formatter: (cellContent, row) => (
                <Button
                    variant="danger"
                    onClick={() => deleteUser(row._id)}
                >
                    Delete
                </Button>
            )
        }
    ];

    // Pagination options
    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        showTotal: true
    };

    return (
        <Container>
            <Toast bg={alertVariant} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
                <Toast.Body>{alertMessage}</Toast.Body>
            </Toast>
            <Row>
                <Col xs={2}><h4>All Users - {users.length} </h4></Col>

                <Col xs={4}>
                    <input
                        type="text"
                        placeholder="Search by name..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                            marginBottom: "10px",
                            padding: "8px",
                            width: "300px",
                            fontSize: "16px",
                        }}
                    />
                </Col>

                <Col md={{ span: 2, offset: 4 }}>
                    <div style={{ textAlign: 'end' }}>
                        <Button onClick={fetchAllUsers}>Refresh Data</Button>
                    </div>
                </Col>
            </Row>

            <BootstrapTable
                keyField='_id'
                data={filteredUsers}
                columns={columns}
                pagination={paginationFactory(options)}
            />
        </Container>
    );
};

export default AllUsers;
