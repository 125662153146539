import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProjectFiles from './ProjectFiles';
import FreeTrialProject from './FreeTrialProject';
import AllProject from './AllProject';
import AllProjectManual from './AllProjectManual';
import AllUsers from './AllUsers';
import StartCmd from './StartCmd';
import StreamingLogsTable from './StreamLogsTable';

const Admin = ({ user, setUser }) => {
    const navigate = useNavigate();
    const [emailValid, setEmailValid] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [userValid, setUserValid] = useState(false);
    const [key, setKey] = useState('files');


    useEffect(() => {
        const validateAdmin = () => {
            const adminEmails = [process.env.REACT_APP_ADMIN_EMAIL_ONE, process.env.REACT_APP_ADMIN_EMAIL_TWO];
            if (user && user.isAdmin && adminEmails.includes(user.email)) {
                setEmailValid(true);
            } else {
                handleLogout();
            }
        };

        validateAdmin();
    }, [user]);

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userId');
        setUser(null);
        navigate('/');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === process.env.REACT_APP_ADMIN_PASS) {
            setUserValid(true);
        } else {
            setError('Invalid password');
            handleLogout();
        }
    };

    return (
        <Container >
            {emailValid && !userValid && (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            )}

            {userValid && (
                <>
                 <Tabs
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
     
      <Tab eventKey="files" title="Project Files">
        <ProjectFiles user={user}/>
      </Tab>

      <Tab eventKey="allProject" title="Project">
        <AllProject user={user}/>
      </Tab>

      <Tab eventKey="freeTrial" title="Free Trial Projects">
        <FreeTrialProject user={user}/>
      </Tab>


      
      <Tab eventKey="allProjectManual" title=" Active Subscriptions">
        <AllProjectManual user={user}/>
      </Tab>

      <Tab eventKey="allUsers" title="All Users">
        <AllUsers user={user}/>
      </Tab>

      
      <Tab eventKey="cmd" title="Cmd">
        <StartCmd user={user}/>
      </Tab>


            
      <Tab eventKey="streamlogs" title="StreamLogs">
        <StreamingLogsTable/>
      </Tab>

    </Tabs>
                    
                    
                </>
            )}
        </Container>
    );
};

export default Admin;
