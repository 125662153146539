import React from 'react';
import { Container } from 'react-bootstrap';

const Body = ({ children }) => {
    return (
        <Container  style={{ marginTop:"100px",marginBottom:"7%"}}>
            {children}
        </Container>
    );
};

export default Body;
