import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LiveProjectData = ({ projectId }) => {

    const [liveData, setLiveData] = useState({}); 

    const fetchLiveData = async () => {
        try {
            const response = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`);
            if (response.status === 200) {
        
   
                setLiveData(response.data); 
            }
        } catch (error) {
            console.error('Error fetching live data:', error);
        }
    };

    useEffect(() => {
        fetchLiveData(); // Initial fetch
        const intervalId = setInterval(fetchLiveData, 5000); 

        return () => clearInterval(intervalId); 
    }, [projectId]);

    return (
        <tr>
            <td>{liveData.totalDuration}</td>
            <td>{liveData.liveUsers}</td>
            <td>{liveData.usersInQueue}</td>
        </tr>
    );
};

export default LiveProjectData;
