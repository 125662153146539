import React, { useState,useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';
import { Button } from 'react-bootstrap';


const CreateProject = ({ user }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [projectId, setProjectId] = useState('');
    const [subId, setSubId] = useState('');

    const navigate = useNavigate();
    const rzpRef = useRef();

    const handleChange = (e) => {
        setName(e.target.value);
    };

    useEffect( ()=>
        {
        if (user.customerId == null) {
            navigate('/account');
          } 
       },[user])
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
//LIVE - "plan_OWwV1eN7eN6gzO",live indian plan_Oek6mdcqhKxad7

//DEV - "plan_OWuyB3BrwQdtdB", Dev Indian - plan_OS4hWHAQSynFHS"
            let plainId = "plan_OWwV1eN7eN6gzO";
            if(user.address.country == "India"){
                plainId = "plan_Oek6mdcqhKxad7"
            }
            setLoading(true); // Start loading indicator
            const today = moment().add(5, 'minutes');
            const unixEndDate = today.unix();

            
            const response = await axios.post('https://api.streampixel.io/pixelStripeApi/projects', {
                name,
                status: false,
                user: user._id,
                allowedUsers: 2,
                isFreeTrial:false,
                isLocal:false,
                subscriptionStartDate: unixEndDate,
                planId:plainId,
                webIp: process.env.REACT_APP_WEBIP
            });


            setUserName(response.data.name);
            setEmail(response.data.email);
            setContact(response.data.contact);
            setProjectId(response.data.projectId);
            setSubId(response.data.subId);

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                subscription_id: response.data.subId,
                name: 'Streampixel',
                description: 'Basic Streaming Plan',
                handler: async function (res) {
                    setLoading(true);

                    const resProject = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${response.data.projectId}`, {
                       
                        userId: user._id,
                        webIp:process.env.REACT_APP_WEBIP,
                        paymentMethodAttached:true,
                    
                });
                        navigate(`/projectDetail/${response.data.projectId}`)
                    
                },
                modal: {
                    ondismiss: async function () {
                        try {
                            const res = await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${response.data.projectId}`, {
                               
                                    userId: user._id,
                                    webIp: process.env.REACT_APP_WEBIP,
                                    paymentMethodAttached:false,
                                    subStatus:"regular",
                               
                            });

                            navigate(`/projectDetail/${response.data.projectId}`);
                        } catch (error) {
                            console.error('Error updating project:', error);
                        }
                    }
                },
                prefill: {
                    name: response.data.name,
                    email: response.data.email,
                    contact: response.data.contact
                },
                theme: {
                    color: '#F37254'
                }
            };

            rzpRef.current = new window.Razorpay(options);
            rzpRef.current.open();

        } catch (error) {
            console.error('Error creating project:', error);
        } finally {
            setLoading(false); // Stop loading indicator
        }
    };

    const handleBackClick = () => {
        navigate('/dashboard');
    };

    return (
        <div>
{loading && (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <p style={{ marginTop: '10px' }}>Please wait...</p>
    </div>
)}

            {!loading && (
                <>
                 <br/>
                 <Button size='sm' variant="primary" onClick={handleBackClick}>
                        <FaArrowLeft /> Back 
                    </Button>
                    <br/>
                    <br/>
                    <h5>Create a Project:</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Project Name</label>
                            <input type="text" className="form-control" id="name" value={name} onChange={handleChange} required />
                        </div>
                        <Button type="submit" className="btn btn-primary">Pay now to continue</Button>
                    </form>
                </>
            )}
        </div>
    );
};

export default CreateProject;
